.appointment_date_picker_wrapper {
  display: flex;
  margin: 15px;
  margin-bottom: 30px;
}

.appointment_date_picker_wrapper .Calendar-page-container {
  width: 70%;
  border-right: 1px solid #d2d2d2 !important;
  box-shadow: none !important;
}

.appointment_date_picker_wrapper .Calendar-timing-wrapper {
  width: 30%;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__tile--active {
  background-color: #111 !important;
  color: #fff !important;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__tile:hover {
  border: 2px solid #000;
  background-color: #e5e7eb;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__tile {
  border: 3px solid #fff;
  border-radius: 10px !important;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view abbr {
  color: unset !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #fff !important;
}

.react-calendar__month-view__days__day--neighboringMonth:hover {
  border: none !important;
  cursor: default !important;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view__days {
  padding: 5px !important;
}

.appointment_date_picker_wrapper .Calendar-page-container .react-calendar__navigation__arrow {
  color: #444;
}

.appointment_date_picker_wrapper .Calendar-timing-wrapper .Calendar-right-panel-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.appointment_date_picker_wrapper .Calendar-timing-wrapper .privateService-wrapper {
  overflow: auto !important;
  height: 550px !important;
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  align-items: center;
}

.appointment_date_picker_wrapper .Calendar-right-panel-wrapper .timeSlot-text {
  border: 1px solid #d1d5db;
  width: 100%;
  padding: 15px;
}

.appointment_date_picker_wrapper .Calendar-right-panel-wrapper .timeSlot-text.selected {
  border: 1px solid #111;
}

.appointment_date_picker_wrapper .Calendar-right-panel-wrapper .timeSlot-text:hover {
  border: 1px solid #111;
  background-color: #fff;
}

.appointment_date_picker_wrapper .Calendar-right-panel-wrapper .timeSlot-text span {
  font-size: 15px;
}

.appointment_date_picker_wrapper .react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-between;
  /* Ensure space between weekdays */
  margin-right: 30px !important;
  /* Ensure the margin is applied */
  padding: 0 10px;
  /* Add padding for better spacing */
  width: 100%;
  /* Set the width to 100% */
  box-sizing: border-box;
  /* Include padding in width */
}


@media (max-width: 600px) {

  .nhsuk-radios,
  .Calendar-page-container {
    margin-top: 0;
    padding-top: 0;
  }

  .appointment_date_picker_wrapper .Calendar-right-panel-wrapper .timeSlot-text {
    padding: 8px;
  }

  .appointment_date_picker_wrapper {
    display: block !important;
  }

  .appointment_date_picker_wrapper .react-calendar__month-view__weekdays__weekday {
    flex: 1;
    /* Ensure each weekday takes equal space */
    text-align: center;
    /* Center align the text */
    padding: 10px;
    /* Add padding for better spacing */
  }

  .appointment_date_picker_wrapper .Calendar-page-container {
    border: none !important;
    width: 100% !important;
  }

  .booking_date_time_selection_wrapper .nhsuk-width-container {
    width: 100% !important;
  }

  .appointment_date_picker_wrapper .Calendar-timing-wrapper {
    width: 100% !important;
  }

  .Calendar-timing-wrapper .Calendar-right-panel-wrapper {
    margin-left: 0px !important;
  }

  .user-confirmation .booking_content {
    margin: 15px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .custom-calendar .react-calendar__month-view__weekdays {
    position: absolute !important;
    left: 0px;
    margin-top: -20px !important;
  }

  .appointment_date_picker_wrapper .react-calendar__month-view__weekdays {
    padding: 0 25px;
  }

  .appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view__days {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }

  .user-confirmation .user-confirmation-form {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .appointment_date_picker_wrapper {
    margin: 0px !important;
  }

  .appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view abbr {
    font-size: 16px !important;
  }

  .user_already_booked_setion h4 {
    font-size: 16px !important;
  }
}

@media (max-width: 480px) {
  .appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view abbr {
    font-size: 16px !important;
    /* Adjust as needed for medium mobile screens */
  }
}

/* Media query for small mobile screens (e.g., 320px and below) */
@media (max-width: 320px) {
  .appointment_date_picker_wrapper .Calendar-page-container .react-calendar__month-view abbr {
    font-size: 16px !important;
    /* Adjust as needed for small mobile screens */
  }
}