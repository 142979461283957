.Booking_overview_container{
  /* background-color: rgb(240, 240, 240); */
  height: auto;
  border: 2px solid rgb(119, 174, 121);
  /* border-radius: 15px; */
  margin: 0px;
  padding: 0px;
}
.Booking_overview_container .tab-header{
  margin: 20px;
  padding: 0 20px;
  height: 100px;
  /* background-color: rgb(240, 240, 240); */
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper_wrapper{
  height: 70px;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 5px;
  /* background-color: #fff; */
  display: block;
  padding-top: 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.booking_overview_content{
  margin: 40px;
  margin-top: 40px;
}

.Booking_overview_container .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #90c48b !important;
}
.Booking_overview_container .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #90c48b !important;
}

.Booking_overview_container .app_logo{
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin: 20px;
  margin-left: 40px;
}
.Booking_overview_container .app_logo img{
  border-radius: 50%;
  margin: 5px;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.app_logo_text {
  text-align: center;
  margin-left: 40px;
  color: #90c48b;
}

/* ---------------------------------------------------------------------------------------------------- */

.stepper {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
}

.step {
  width: 25%;
  flex: 1;
  position: relative;
  padding: 30px;
  border-radius: 2px;
  text-align: center;
  align-items: center;
  /* background-color: #fff; */
  /* border: 1px solid #444; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.step.active {
  /* background-color: #d1e9c6;  */
  color: #000; 
  font-weight: 500;
}

.step.completed {
  /* background-color: #d1e9c6;  */
  /* color: #000;  */
  font-weight: 500;
}

.step:last-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: -50%;
  /* width: 50%; */
  height: 2px;
  background-color: #3a7520; /* Connector line color */
}

.theme-selector{
  width: 110px;
  position: absolute;
  right: 0;
  /* margin: 30px; */
  margin-right: 60px;
  margin-top: -50px;
}
.theme-selector select{
  padding: 10px;
  border-radius: 15px;
  color: #fff;
  background-color: #222;
  width: 100%;
  cursor: pointer;
}


 