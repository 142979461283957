.booking_wrapper {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.booking_wrapper_expanded {
    height: auto !important;
    padding-bottom: 84px !important;
}

.booking_wrapper .menubar_container {
    background-color: #fff;
}

.appointment-scheduler {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    width: 80%;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 50px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
}

/* .booking_content_wrapper body{
    background-color: #f2f2f2;
} */

.booking_content_wrapper h1 {
    padding-bottom: 15px;
}

.booking_content_wrapper p {
    color: #888888;
    font-weight: 400;
    transition: all 500ms ease;
}

.dropdown {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.calendar {
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-size: 14px;
    height: 80px;
}

.calendar-slots {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.slot-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: 10px;
}

.slot {
    background-color: green;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.not_available_slot {
    background-color: #d61a1a;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: not-allowed; 
}

.slot.disabled {
    background-color: #ddd;
    color: #666;
}

.slot:hover {
    background-color: rgb(1, 64, 1);
}

.calendar-ui {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}

.nav-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    color: #555;
}

.days {
    display: flex;
    justify-content: space-between;
    width: 75%;
}

.day {
    text-align: center;
    font-size: 14px;
    padding: 5px 15px;
}

.day:hover {
    background-color: #f0f0f0;
}

.nav-button .fas {
    background-color: transparent;
    border: none;
    font-size: 18px !important;
    cursor: pointer;
    padding: 5px;
    color: #555;
    margin: 0px !important;
}

.nav-button.disabled {
    cursor: not-allowed !important;
}

.disabled-icon {
    color: lightgray !important;
    cursor: not-allowed !important;
}

.appointment-scheduler .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #888888 !important;
    border-width: 1px !important;
}

.appointment-scheduler .loading-dots{
    text-align: center;
}
.appointment-scheduler .loading-dots .loading{
    font-size: 80px;
    color: green;
}

@media (max-width: 425px) {
    .calendar-slots {
        display: block !important;
        margin-right: 15px !important;
    }

    .nav-button {
        margin-right: -10px !important;
    }

    .calendar-header {
        padding: 0px !important;
    }

    .calendar-header {
        margin: -8px !important;
    }

    .calendar-header {
        padding: 0px !important;
    }

    .calendar-header {
        padding: 12px !important;
    }

    .appointment-scheduler {
        width: 98% !important;
        padding: 8px !important;
    }
    .days{
        width: 40% !important;
    }
    .calendar-ui{
        margin-right: 10px !important;
    }
    .login_form{
        width: 98% !important;
        padding: 8px !important;
    }
    .login_form h4{
        font-size: 15px !important;
        margin-bottom: 15px !important;
    }
    .service_title_wrapper p{
        font-size: 13px !important;
    }
    input, select{
        font-size: 12px !important;
    }
    label {
        font-size: 14px !important;
    }
    .pre-consultation-form span{
        font-size: 14px !important;
    }
    .booking_back_button{
        font-size: 14px !important;
        margin-bottom: 15px !important;
    }
}