.rating-experience h5 {
  margin-bottom: 20px;
}

.rating-container {
  width: fit-content;
}

.rating-container h5 {
  margin-bottom: 20px;
}

.rating-labels {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.rating-container .MuiRating-icon {
  font-size: 55px;
}

.rating-labels p:first-child {
  margin-left: 15px;
}

@media only screen and (max-width: 600px) {
  .rating-container {
    margin: 0 auto;
  }
}
