.instruction_main_container .nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.instruction_main_container .nav-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #08649e;
  padding: 10px;
}
.instruction_main_container .pre-consultation-page {
  background-color: #f2f2f2;
  width: 100%;
  height: auto;
  /* text-align: center; */
  display: flex;
  padding-top: 30px !important;
  /* align-items: center; */
  justify-content: center;
}
.instruction_main_container .content_page {
  padding: 20px;
  background-color: #fff;
  width: 60%;
  height: auto;
  left: 50%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.booking_dateTime{
  padding: 10px;
  margin-top: 20px;
}
.instruction_main_container .content_page h2 {
  text-align: center;
  margin-top: 15px;
}
.pre-consultation-page a {
  cursor: pointer;
  text-decoration: underline;
  color: red;
}

.next_button {
  cursor: pointer;
  padding: 15px;
  width: 200px;
  background-color: #444;
  color: #fff;
  margin-top: 40px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.next_button:hover {
  background-color: #fff;
  color: #444;
  border: 1px solid #444;
}
.confirmation_main_wrapper{
  padding: 10px;
}
.confirmation_main_wrapper .content_title{
  display: flex;
  justify-content: space-between;
}
.content_title span{
  font-size: 16px;
  font-weight: 500;
  padding-top: 15px;
}
.footer_btns{
  margin-top: 20px;
}