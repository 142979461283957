.question_section .nhsuk_container {
  width: 100%;
  /* height: 100%; */
  background-color: #f0f4f5;
}
.question_section .nhsuk-grid-column-two-thirds {
  /* width: 700px; */
  height: auto;
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  margin-bottom: 100px !important;
  border-radius: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.image_container img {
  width: 300px;
  height: 200px;
  margin: 15px;
}
.nhsuk-button {
  border: none;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 30px;
}
.back_button {
  cursor: pointer;
  text-decoration: underline;
  color: #049096;
  font-size: 20px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Modal Content */
.modal-content {
  background-color: #f0f0f0;
  width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.questions_List .MuiSvgIcon-root {
  width: 25px !important;
  color: rgb(10, 194, 10) !important;
}
.question_answers_section {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

/* Close Button */
.modal-content button {
  background-color: #21642d; /* Blue color, adjust as needed */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
}

/* Close Button Hover */
.modal-content button:hover {
  background-color: #3a8949; /* Darker blue color on hover, adjust as needed */
}
.completed_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.completed_image img {
  width: 150px;
}

.question_section .nhsuk-width-container {
  margin-right: 85px !important;
  margin: 30px !important;
  width: 100% !important;
}

.question_section .Error_message {
  color: rgb(213, 63, 63);
}

@media (max-width: 600px) {
  .modal-content {
    width: 230px !important;
  }
  .nav-title h3 {
    display: none !important;
  }
  .question_section .nhsuk-width-container {
    margin-right: unset !important;
    margin: unset !important;
    width: unset !important;
  }
  .user-confirmation .user-confirmation-form {
    height: calc(100vh - 62px);
  }
  .user-confirmation .nhsuk-button {
    width: 100% !important;
  }
  .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    margin-left: -25px !important;
  }
  .user_appointment_completed_setion
    .meeting_schedule_wrapper
    .meeting_internal_info {
    width: 70% !important;
  }
  .user_appointment_completed_setion
    .meeting_schedule_wrapper
    .meeting_internal_heading {
    width: 30% !important;
  }
}
