.menubar_container {
  /* margin-left: 50px;
  margin-right: 20px;
  padding: 10px; */
}

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  background-color: #f2f2f2;
  /* font-family: 'segoe ui'; */
}

.nav {
  height: auto;
  width: 100%;
  background-color: #fff;
  position: relative;
}

.nav > .nav-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

.nav > .nav-header > .nav-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #08649e;
  padding: 10px;
}

.nav-header .pharmacy_name {
  margin-left: unset;
}

.nav-title img {
  width: 100px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: flex;
  /* Use flex to make the nav-links appear in one line */
  font-size: 18px;
  position: absolute;
  top: 100px;
  /* Adjust this value based on your design */
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: height 0.3s ease-in;
  overflow: hidden;
  z-index: 1;
}

.nav > .nav-links > a {
  /* flex: 1;
  padding: 13px 10px; */
  margin: 40px;
  text-decoration: none;
  color: #08649e;
}

.nav > .nav-links > a:hover {
  text-decoration: underline;
  color: #08649e;
}

#nav-check:checked + .nav > .nav-links {
  height: calc(100vh - 50px);
  /* Adjust this value based on your design */
}

.nav > #nav-check {
  display: none;
}

/* -------------------- home page image work------------------------------------------------------------- */

.dashboard_body_container {
  margin-top: 106px;
}

.image-overlay-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.dashboard_body_container .full-width-image {
  width: 100%;
  height: 700px;
  display: block;
}

.dashboard_body_container .text-overlay {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  /* Adjust text color as needed */
}

.dashboard_body_container .text-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #1065c1;
  font-weight: bolder;
}

.dashboard_body_container .text-overlay p {
  font-size: 1.2rem;
  margin: 0;
  color: #444;
}

/* --------------------home page image end------------------------------------------------------- */

/* -----------------Social info tags--------------------------------------------------------------------- */
.social_info_container {
  width: 100%;
  height: 40px;
  padding: 50px;
  align-items: center;
  background-color: rgb(234, 238, 240);
  display: flex;
  justify-content: space-between;
}

.general_info_container {
  display: flex;
  align-items: center;
  height: 400px;
  padding: 50px;
}

.general_info_container div {
  padding: 50px;
}

.about_us_container {
  width: 100%;
  height: 500px;
  padding: 50px;
  background-color: rgb(234, 238, 240);
}

.about_us_container h1 {
  text-align: center;
}

.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about_us_container .card {
  flex: 0 0 calc(33.333% - 20px);
  /* Adjust the width of each card */
  margin-bottom: 20px;
  /* Adjust the margin between cards */
  background-color: transparent;
  /* Set the transparent background color */
  border: none !important;
}

.about_us_container .css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}

.services_container {
  margin-top: 50px;
  padding: 50px;
}

.services_container h1 {
  text-align: center;
  margin-bottom: 25px !important;
}

.services_container .card {
  flex: 0 0 calc(33.333% - 20px);
  /* Adjust the width of each card */
  margin-bottom: 20px;
  /* Adjust the margin between cards */
  background-color: transparent;
  /* Set the transparent background color */
  border: 2px solid rgb(148 163 184);
  border-radius: 0.5rem !important;
}

.services_container .css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
}
.custom-icon {
  font-size: 50px !important; /* Set the desired size */
}

.services_container .card-footer {
  border-top: unset !important;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}
.services_container .footer_button_icon {
  color: #fff;
  background-color: #08649e;
  border-radius: 50%;
}
.services_container .footer_button {
  color: #6e6e6e !important;
  font-weight: bold;
}

.card-content {
  display: flex;
  align-items: center;
}

.left-content {
  flex: 1;
}

.right-content {
  margin-left: 20px;
  /* Adjust the margin as needed */
}

.circular-image {
  max-width: 200px;
  height: 200px;
  border-radius: 50%;
}
.accordian_container {
  width: 100%;
  height: auto;
  padding: 50px;
  background-color: #f6f1ef;
}
.questionAir_section {
  width: 100%;
  height: auto;
  padding: 50px;
  background-color: #fff;
}
.questionAir_section h1 {
  text-align: center;
  margin-bottom: 40px;
}
.accordian {
  background-color: #f6f1ef;
  margin: 50px;
  padding: 50px;
  border-radius: 20px;
}
.accordian_container h1 {
  text-align: center;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #f6f1ef !important;
  border-bottom: 1px solid black !important;
}
.css-1jz15ig-MuiPaper-root-MuiAccordion-root .MuiAccordionDetails-root {
  background-color: #f6f1ef !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: unset !important;
}
.css-15v22id-MuiAccordionDetails-root {
  background-color: #f6f1ef !important;
}
.css-1jz15ig-MuiPaper-root-MuiAccordion-root {
  box-shadow: unset !important;
}
.about_services_contaier {
  width: 100%;
  height: auto;
  display: flex;
}
.about_services_contaier .text-container {
  flex: 1;
  padding: 50px;
  line-height: 4.5;
}
.about_services_contaier .image-container {
  flex: 1;
  padding: 50px;
  order: 2;
}

.about_services_contaier .image-container .image {
  width: 700px;
  height: 600px;
}

.footer {
  width: 100%;
  height: 300px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.footer h1 {
  text-align: center;
  padding-top: 40px;
  /* margin: 50px; */
}
.app_logo_text {
  text-align: center;
  margin-left: 40px;
  color: #90c48b;
}
/* ------------------Media Quary---------------------------------------------------------------------------------- */

@media (max-width: 600px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
    margin-top: 10px;
  }

  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: transparent;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #08649e;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #fff;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .dashboard_body_container {
    margin-top: 0px !important;
  }

  .dashboard_body_container .full-width-image {
    height: 300px !important;
  }

  .dashboard_body_container .text-overlay {
    left: 35% !important;
  }

  .dashboard_body_container .text-overlay h1 {
    font-weight: bold !important;
    font-size: 20px !important;
  }

  .dashboard_body_container .text-overlay p {
    font-size: 15px !important;
  }

  .social_info_container {
    padding: 10px !important;
    height: 80px !important;
  }

  .social_menu_items {
    font-size: 10px !important;
  }

  .general_info_container div h1 {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .general_info_container {
    padding: 5px !important;
  }

  .general_info_container div {
    margin-top: -130px !important;
    padding: 5px !important;
  }

  .about_us_container {
    height: auto !important;
    padding: 20px !important;
  }

  .about_us_container h1 {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .about_us_container .card {
    flex: 0 0 100%;
    /* Full width for smaller screens */
  }

  .services_container .card {
    flex: 0 0 100% !important;
    /* Full width for smaller screens */
  }
  .circular-image {
    max-width: 100px;
  }
  .services_container {
    padding: 10px !important;
  }
  .about_services_contaier {
    flex-direction: column; /* Change to column layout on small screens */
  }
  .about_services_contaier .image-container {
    padding: 10px !important;
    order: 1 !important; /* Change the order for small screens */
  }
  .about_services_contaier .text-container {
    line-height: 1.5;
    padding: 10px;
  }
  .about_services_contaier .image-container .image {
    width: 300px !important;
    height: auto !important;
  }
  .accordian {
    margin: 5px;
    padding: 10px;
  }
  .questionAir_section {
    padding: 5px !important;
  }
  .accordian_container {
    padding: 5px !important;
  }
  .menubar_container {
    margin-left: 0px !important;
  }

  .user_already_booked_setion .nhsuk-width-container {
    height: 750px !important;
  }
  .user_already_booked_setion .decission_buttons button {
    margin-left: 0px !important;
  }
}
