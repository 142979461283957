.location-page-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  /* background-color: white; */
  border: 1px solid rgb(232, 232, 232);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.location-text {
  align-self: flex-start;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.location-page-container .select-button {
  align-self: flex-end;
  width: 170px;
  border-radius: 5px;
  /* background-color: #6fac6a; */
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.location-page-container .select-button:hover {
  /* background-color: #90c48b; */
}

.location_map_wrapper{
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}
.location_map_wrapper .location-link{
  text-decoration: none;
  color: #337ab7;
  font-weight: 500;
  font-size: 14px;
}

.location_map_wrapper .location-link:hover{
  text-decoration:underline;
}

.arrow-icon{
  margin: 4px;
}
.location-icon{
  margin: 4px;
}

.map_wrapper{
  width: 50%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
}

.full-width-height-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.map_container{
  margin-top: 20px;
  display: flex;
  justify-content:flex-start;
  width: 100%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
}

.map_address_container{
  margin-top: 15px;
  margin-left: 20px;
}
.location-link-div{
  cursor: pointer;
}

.separater{
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: rgb(205, 205, 205);
}
.map_wrapper img{
  border-radius: 10px;
}

