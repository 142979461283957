.consent_page_header {
  background-color: #005eb8;
  padding: 20px;
}
.nhsuk-button {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 28px;
  background-color: #444;
  border: 2px solid transparent;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 0;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: auto;
  text-decoration: none;
}
@media (min-width: 40.0625em) {
  .nhsuk-button {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}
.nhsuk-button:link,
.nhsuk-button:visited,
.nhsuk-button:active,
.nhsuk-button:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #2a2a2a;
}
a:visited {
  color: #330072;
}
.nhsuk-width-container {
  /* margin: 0 16px !important; */
  width: 100%;
}
.user-confirmation-form .nhsuk-width-container {
  background-color: #fff;
  padding: 20px !important;
  padding-top: 30px !important;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.user_appointment_completed_setion .nhsuk-width-container {
  width: 100%;
  height: 400px !important;
}
.nhsuk_container {
  width: 100%;
  justify-content: center;
}
.user-confirmation .user-confirmation-form {
  width: 100%;
  height: calc(100vh - 62px);
  padding: 50px;
  background-color: #f1f1f1;
}
.user-confirmation .pharmacy_name {
  font-weight: 600;
  margin-left: 12px;
}
.nhsuk-radios__item {
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  clear: left;
  display: block;
  margin-bottom: 8px;
  min-height: 40px;
  padding: 0 0 0 40px;
  position: relative;
}
.nhsuk-radios__input {
  cursor: pointer;
  height: 40px;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 1;
}
.nhsuk-radios__input + .nhsuk-radios__label::before {
  background: #ffffff;
  border: 2px solid #4c6272;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}
.nhsuk-radios__input + .nhsuk-radios__label::after {
  background: #4c6272;
  border: 10px solid #212b32;
  border-radius: 50%;
  content: "";
  height: 0;
  left: 10px;
  opacity: 0;
  position: absolute;
  top: 10px;
  width: 0;
}
.nhsuk-radios__input:checked + .nhsuk-radios__label::after {
  opacity: 1;
}
.nhsuk-radios__label {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 12px 4px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.nhsuk-back-link__link {
  align-items: center;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  color: #005eb8;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 0 0 16px;
  position: relative;
  text-decoration: none;
  display: flex;
}
.nhsuk-back-link__link:visited {
  color: #005eb8;
}
.nhsuk-back-link__link:hover {
  color: #7c2855;
  text-decoration: underline;
}
.nhsuk-radios__input:focus + .nhsuk-radios__label::before {
  border: 4px solid #212b32;
  box-shadow: 0 0 0 4px #ffeb3b;
}
.nhsuk_container .nhsuk-fieldset__heading {
  text-align: center;
  color: #27b7f1;
}
.nhsuk-fieldset__heading {
  text-align: center;
  color: #27b7f1;
}

.nhsuk-fieldset input {
  background-color: transparent !important;
}

.nhsuk_fieldset input:-internal-autofill-selected {
  background-color: transparent !important;
}

.nhsuk_title_heading_container {
  background: rgb(67, 59, 59) 848 !important;
  width: 100%;
  height: 70px;
}
.main_container {
  height: auto;
  margin: 30px;
  border: 1px solid #444;
  border-radius: 10px;
  padding-bottom: 40px;
}
.nhsuk_Logo_wrapper {
  text-align: center;
}
.nhsuk_Logo_wrapper h1 {
  color: #27b7f1;
}
.nhsuk_heading_wrapper {
  height: 70px;
  background-color: rgb(57, 49, 49);
  text-align: center;
  margin-top: 20px;
}
.nhsuk_heading_wrapper h1 {
  color: #fff;
  padding-top: 10px;
}
.nhsuk_content_wrapper {
  padding: 20px;
}
.nhsuk_footer_wrapper {
  text-align: center;
}
.nhsuk_footer_wrapper button {
  color: #fff;
  display: block;
  margin: 10px auto;
  background-color: rgb(85, 83, 83);
  height: 50px;
  width: 320px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.nhsuk_footer_wrapper a {
  color: rgb(52, 112, 190) !important;
  text-decoration: underline !important;
  cursor: pointer;
  margin-top: 30px;
}
.nhsuk_footer_wrapper div {
  margin-top: 40px;
}
.nhsuk_info_btns__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
}
.nhsuk_info_btns__wrapper button {
  background-color: rgb(85, 83, 83);
  height: 50px;
  max-width: 200px;
  min-width: 200px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 30px;
  color: #fff;
}
.nhsuk_info_btns__wrapper div a {
  color: rgb(52, 112, 190) !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.nhsuk_info_btns__wrapper div {
  margin-top: 30px !important;
}
.click_here_link {
  color: rgb(52, 112, 190) !important;
  text-decoration: underline !important;
  cursor: pointer;
}
/* ======================================================= */
.personal_info_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
}
.personal_info_form .info_fields_wrapper {
  width: 100%;
}
.personal_info_form .info_fields_wrapper input {
  width: 97%;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
}
.personal_info_form .info_fields_wrapper label {
  width: 97%;
  padding: 10px;
  margin: 20px;
}
.nhsuk-link {
  display: flex;
  align-items: center;
}
.nhsuk-link a {
  color: rgb(52, 112, 190) !important;
  text-decoration: underline !important;
  cursor: pointer;
}
/* --------------------Custom radio buttons-------------------------------------------- */
.user-confirmation
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #404040 !important;
}

.user-confirmation .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}
.user-confirmation .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 20px !important;
}
.nhsuk-form-group .MuiSvgIcon-root {
  /* width: 35px !important; */
  color: #404040 !important;
  height: 35px !important;
}
.nhsuk-form-group .MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 16px !important;
}
.nhsuk-radios {
  margin-top: 30px;
}
.nhsuk-back-link {
  margin-left: -20px !important;
}
.error_message_text {
  color: rgb(253, 17, 17);
  top: 50%;
  left: 50%;
}
.custom_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.template_control {
  margin-top: 20px;
  flex-direction: column;
  border-radius: 11px;
  display: flex;
  width: 100%;
  /* padding: 6px 15px; */
  /* min-width: 335px; */
}
.template_control_label {
  flex-direction: column;
  border-radius: 11px;
  display: flex;
  gap: 200px;
  width: 100%;
  /* padding: 6px 15px; */
  /* min-width: 335px; */
}

.user_appointment_completed_setion .succes_gif_info {
  text-align: center;
  margin-top: -20px;
}
.user_appointment_completed_setion .meeting_schedule_wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  /* line-height: 50px; */
}
.user_appointment_completed_setion
  .meeting_schedule_wrapper
  .meeting_internal_heading {
  font-weight: 600;
  width: 50%;
}
.user_appointment_completed_setion
  .meeting_schedule_wrapper
  .meeting_internal_info {
  /* font-weight: 600; */
  width: 50%;
}
.user_already_booked_setion .nhsuk-width-container {
  height: auto !important;
}
.user_already_booked_setion .make_change_to_appointment {
  /* text-align: center; */
}
.user_already_booked_setion .make_change_to_appointment span {
  font-weight: 700;
}
.user_already_booked_setion .decission_buttons {
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 10px; 
}
.user_already_booked_setion .decission_buttons button {
  margin-left: 0px !important;
  height: 40px !important;
  font-size: 14px;
  padding-top: 9px;
  margin: 10px;
}

.user_already_booked_setion .decission_buttons .cancel_booking_button {
  background-color: #cf1f1f !important;
}

.user_already_booked_setion h4 {
  font-weight: 700;
  text-align: center;
}
.confirm_booking_button{
  background-color: #3EBF32 !important;
}

.user-confirmation-form .loading-dots .loading{
  font-size: 50px;
  color: green;
}

.user-confirmation-form .loading-dots {
  font-size: 14px;
  margin-top: 5px;
}

.user-confirmation-form .loading-dots span {
  animation: blink 1s infinite;
}

.user-confirmation-form .loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.user-confirmation-form .loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}