.details-page-container {
  margin-top: 20px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  /* background-color: white; */
  border: 1px solid rgb(232, 232, 232);
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.details-page-container .user_info {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.details-page-container .details_heading {
  text-align: center;
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
}

.details-page-container .user_info_form {
  width: 45%;
  height: 300px;
  padding: 20px;
}

.user_info .form-group {
  margin: 30px;
  display: flex;
  /* justify-content:start; */
  margin-bottom: 10px;
}

.user_info label {
  padding-right: 150px;
  display: block;
  font-weight: bold;
}

.user_info label b {
  color: red;
}

.user_info input {
  width: 90%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.input-container {
  /* margin-left: 50px; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.details-page-container .user_selected_info {
  border-left: 2px solid rgb(220, 220, 220);
  width: 50%;
  margin: 10px;
  padding-left: 30px;
  position: relative;
  margin-bottom: 60px !important;
}

.user_selected_info .user_selected-data {
  height: auto;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
}

.user_selected-data .selected_data {
  font-weight: 600;
  margin-left: 100px;
}

.user_selected-data .selected_data_title {
  font-weight: 400;
  font-size: 16px;
}

.user_selected_info .select-button {
  width: auto;
  border-radius: 5px;
  /* background-color: #6fac6a; */
  /* color: #fff; */
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 1;
  right: 0;
  margin-top: 20px;
}

.user_selected_info .select-button:hover {
  /* background-color: #90c48b; */
}

.user_selected_info .location_color {
  /* color: #599654; */
  cursor: pointer;
}

.user_selected_info .location_color:hover {
  text-decoration: underline;
}

.terms_and_condition_wrapper {
  margin-top: 30px;
  width: 100%;
}

.terms_and_condition_wrapper label {
  display: flex;
  align-items: center;
}

.terms_and_condition_wrapper a {
  margin-left: 20px;
  text-decoration: none;
  color: #599654;
}

.terms_and_condition_wrapper a span {
  color: red;
}

.checbox_input_field {
  width: 20px !important;
  height: 20px;
  margin-left: 16px;
  appearance: none;
  vertical-align: middle;
  position: relative;
}
.checbox_input_field:checked {
  /* background-color: #6fac6a !important;  */
}

.checbox_input_field:checked::before {
  content: '\2713' !important;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-message {
  /* margin-left: 50px; */
  color: red;
}

.recaptcha-wrapper{
  margin-left: 15px;
  margin-top: 30px;
}

#phone{
  width: 91% !important;
  margin-left: -6px !important;
}




